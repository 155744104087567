import React, { useEffect, useRef, useState } from 'react'

// Styles
import './index.css'

// Components
import Header from '../../components/Sections/Header'
// import Carousel from '../../components/Sections/Carousel'
// import CustomersSerction from '../../components/Sections/Customers'

const Index = () => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const reportRef = useRef(null)

    useEffect(() => {
        const handleScroll = () => {
            if (reportRef.current) {
                const rect = reportRef.current.getBoundingClientRect()
                const isVisible = rect.top + (window.innerHeight - 200) < window.innerHeight && rect.bottom >= 0
                setIsModalVisible(isVisible)
            }
        }

        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    return (
        <div className='app'>
            <Header />
            <main className='main-content'>
                <section className='hero'>
                    <h1>
                        Понятные пациентам
                        <br />
                        медицинские документы
                    </h1>
                    <div className={'report-sample-button-container'}>
                        <a href='/app/'>
                            <button className='sample-report-button big'>Попробовать</button>
                        </a>
                        <div ref={reportRef} className='report-container'>
                            <img src={'/assets/images/report-image.png'} alt='Пример отчета' className='report-image' />
                            <img
                                src={'/assets/images/hint-modal.png'}
                                alt='Пример модалки'
                                className={`term-modal-image ${isModalVisible && 'visible'}`}
                            />
                        </div>
                    </div>
                </section>
                <section className='features'>
                    <h1>
                        Объясните сложные термины <br /> простым языком и наглядными картинками
                    </h1>
                </section>
                <section className='how-it-works'>
                    <div className='how-it-works-content'>
                        <div className='how-it-works-image'>
                            <img src={'/assets/images/how-it-works-image.png'} alt='Пример радиологического отчета' />
                        </div>
                        <div className='how-it-works-text'>
                            <h2>Как это работает</h2>
                            <h3>Медицинские термины автоматически выделяются и объясняются в каждом документе</h3>
                            <p>
                                Наше ПО автоматически анализирует текст любого медицинского документа, выделяет и переводит терминологию на
                                простой язык, а затем отображает отчёт в интерактивном формате, ориентированном на пациента.
                            </p>
                            <p>
                                Пациенты с удовольствием нажимают на медицинские термины и фразы, чтобы увидеть объяснение прямо рядом с
                                отчетом
                            </p>
                            <a href='/app/'>
                                <button className='sample-report-button'>Посмотреть пример</button>
                            </a>
                        </div>
                    </div>
                </section>
                <section className='benefits-for-health-systems'>
                    <div className='benefits-content'>
                        <div className='benefits-image'>
                            <img src={'/assets/images/how-it-works-image.png'} alt='Пример радиологического отчета' />
                        </div>
                        <div className='benefits-text'>
                            <h2>Преимущества для систем здравоохранения</h2>
                            <h3>Увеличиваем вовлеченность и удовлетворенность пациентов</h3>
                            <p>
                                Пациенты получают доступ к понятной и интерактивной медицинской документации. Интуитивно понятный интерфейс
                                и качественный контент значительно улучшают впечатление от оказанной медицинской помощи.
                            </p>
                            <a href='/app/'>
                                <button className='book-demo-button'>Посмотреть пример</button>
                            </a>
                        </div>
                    </div>
                </section>
                {/*<Carousel />*/}
                {/*<CustomersSerction />*/}
                {/*<section className='sd-section'>*/}
                {/*    <div className='sd-content'>*/}
                {/*        <h3 className='sd-subtitle'>Scanslated Direct</h3>*/}
                {/*        <h2 className='sd-title'>*/}
                {/*            Ваши радиологические отчеты, <span className='sd-highlight'>объясненные</span>.*/}
                {/*        </h2>*/}
                {/*        <p className='sd-description'>*/}
                {/*            Впервые наш революционный сервис перевода радиологических отчетов также доступен для широкой публики.*/}
                {/*            Зарегистрируйтесь, чтобы начать создавать свои собственные отчеты, понятные для пациентов, уже сегодня!*/}
                {/*        </p>*/}
                {/*        <button className='sd-try-button'>Попробовать Scanslated Direct</button>*/}
                {/*    </div>*/}
                {/*    <div className='sd-phone-image'>*/}
                {/*        <img src={'/assets/images/directPhone.png'} alt='Пример отчета на смартфоне' />*/}
                {/*    </div>*/}
                {/*</section>*/}
                <footer className='footer'>
                    <h2 className='footer-title'>Получи в один клик!</h2>
                    <a href='mailto:info@scanslated.ru' className='footer-email'>
                        info@scanslated.ru
                    </a>
                    <div className='footer-bottom'>
                        <p className='footer-copyright'>© 2024 ООО &quot;Оксиджен Технолоджиес Рус&quot;</p>
                    </div>
                </footer>
            </main>
        </div>
    )
}

export default Index
