import React from 'react'
import './index.css'

const Header = () => {
    return (
        <div>
            <header className='header'>
                <div className='header-content'>
                    <img src={'/assets/images/logo.png'} alt='Scanslated Logo' className='logo' />
                    <nav className={`nav`}>
                        <a href='/app/'>
                            <button className='sample-report-button'>Попробовать</button>
                        </a>
                    </nav>
                </div>
            </header>
        </div>
    )
}

export default Header
